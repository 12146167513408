#app-login {
  text-align: center;
}
#app-login .form-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  background: url("/images/abstract-bg.gif");
  background-repeat: no-repeat;
  background-size: cover;
}
#app-login .form-card {
  max-width: 500px;
  margin: 0px auto;
}
#app-login .form-banner {
  /* padding-top: 20px; */
  padding: 20px 15px 0px;
  border-bottom: 1px solid #eeeeee;
}
#app-login .form-banner-image {
  width: 100%;
}
#app-login .attendlab-logo {
  position: absolute;
  top: 0px;
  left: 0px;
}
#app-login .login-form {
  width: 100%;
}
#app-login .login-form-forgot {
  float: right;
}
#app-login .login-form-button {
  width: 100%;
}
#app-login .login-form-remember-me,
#app-login .login-form-not-my-account {
  float: left;
}

#app-login .login-form-avatar {
  /* margin-top: -34px; */
}
