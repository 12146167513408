.statistic-card {
  width: 90%;
  /* margin: 0px auto !important; */
  margin-bottom: 20px !important;
}
.statistic-card .main-number .ant-statistic-content {
  font-size: 40px !important;
}

.statistic-card .sub-number .ant-statistic-content {
  font-size: 16px !important;
}

.statistic-card .sub-number .ant-statistic-content-value-decimal {
  font-size: 14px !important;
}
