#app-forgetpassword {
  text-align: center;
}
#app-forgetpassword .forgetpassword-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  background: url("/images/abstract-bg2.gif");
  background-repeat: no-repeat;
  background-size: cover;
}
#app-forgetpassword .forgetpassword-card {
  max-width: 500px;
  margin: 0px auto;
}
#app-forgetpassword .forgetpassword-banner {
  /* padding-top: 20px; */
  padding: 20px 15px 0px;
  border-bottom: 1px solid #eeeeee;
}
#app-forgetpassword .forgetpassword-banner-image {
  width: 100%;
}

#app-forgetpassword .back-to-login {
  position: absolute;
  right: 18px;
  top: 34px;
}

#app-forgetpassword .attendlab-logo {
  position: absolute;
  top: 0px;
  left: 0px;
}

#app-forgetpassword .forgetpassword-steps {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}

#app-forgetpassword .enteremail-form {
  width: 100%;
}
#app-forgetpassword .enteremail-form-button {
  width: 100%;
}

#app-forgetpassword .verification-form {
  width: 100%;
}
#app-forgetpassword .verification-form-button,
#app-forgetpassword .verification-form-back-button {
  width: 100%;
}
